<template>
  <header>
    <nav class="navbar fixed-top navbar-expand-md navbar-light bg-white">
      <div class="container-fluid">
        <a class="navbar-brand" :href="homePage"
          ><img
            id="navbar-logo"
            src="@/assets/img/woob_logo_sm.jpg"
            class="small"
        /></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-md-0 d-flex">
            <li>
              <a class="nav-link" :href="tenantURL" style="margin-bottom: 5px"
                >Tenants</a
              >
            </li>
            <li>
              <a class="nav-link" :href="managerURL" style="margin-bottom: 5px"
                >Managers</a
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Contact')"
                >Contact</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Home')"
                >Home</span
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { onUnmounted, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const tenantURL = ref(process.env.VUE_APP_TENANT_URL);
    const managerURL = ref(process.env.VUE_APP_MANAGER_URL);
    const homePage = ref(process.env.VUE_APP_HOME_PAGE);
    const isScrolled = ref(false);
    const router = useRouter();

    const handleScroll = () => {
      isScrolled.value =
        document.body.scrollTop > 50 || document.documentElement.scrollTop > 50;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const closeMenuRoute = routeName => {
      router.push({ name: routeName });
    };

    return {
      handleScroll,
      isScrolled,
      tenantURL,
      managerURL,
      homePage,
      closeMenuRoute
    };
  }
};
</script>
<style>
/* HEADER / TOP MENU
------------------------------------------------- */

#navbar-logo {
  height: 80px;
  transition-property: height;
  transition-duration: 0.2s;
}

#navbar-logo.small {
  height: 40px;
}

li.nav-item {
  text-align: center;
  margin-right: 5px;
}

.navbar-expand-md .navbar-nav .nav-link {
  margin-top: 6px;
  color: #404042;
  font-weight: 600;
  text-transform: uppercase;
}
</style>